<template>
  <div class="mainLeft">
    <div class="left_Title">
      <img src="../../assets/image/logo.png" />
    </div>
    <div class="left_content">
      <div class="left_list" @click="changeSelected(0)">
        <div
          class="list_color"
          :style="{ background: isSelected == 0 ? '#4875ef' : '' }"
        />
        <div class="list_icon ic2" />
        <div
          class="list_text"
          :style="{
            color: isSelected == 0 ? '#fff' : '#d6d9e1',
            fontWeight: isSelected == 0 ? '700' : '400',
          }"
        >
          {{ $t("Custom.Custom") }}
        </div>
      </div>
      <div class="left_list" @click="changeSelected(1)">
        <div
          class="list_color"
          :style="{ background: isSelected == 1 ? '#4875ef' : '' }"
        />
        <div class="list_icon ic1" />
        <div
          class="list_text"
          :style="{
            color: isSelected == 1 ? '#fff' : '#d6d9e1',
            fontWeight: isSelected == 1 ? '700' : '400',
          }"
        >
          {{ $t("Machine.Equipment") }}
        </div>
      </div>
    </div>
    <div class="left_bottom">
      <div class="layout" @click="handleLogOut" />
      <div class="userName" @click="handleOpenUserInfoDialog">
        {{ account }}
      </div>
      <div class="time">{{ date }}</div>
      <!-- <div class="selected">
        <el-select v-model="companyType" @change="changeLan">
          <el-option
            v-for="item in companyTypes"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          />
        </el-select>
      </div> -->
    </div>
    <user-info ref="userInfoDialogRef"></user-info>
  </div>
</template>

<script>
import UserInfo from "../components/userInfo.vue";
import { showModal } from "../../js/util";
import dayjs from "dayjs";
export default {
  name: "MainLeft",
  components: {
    UserInfo,
  },
  data() {
    return {
      isSelected: 0,
      companyType: "zh",
      account: "",
      dataTimer: "",
      date: "",
      time: "",
      companyTypes: [
        {
          value: "中文",
          id: "zh",
        },
        {
          value: "English",
          id: "en",
        },
      ],
    };
  },

  created() {
    this.getTime();
    this.isSelected = this.$route.name === "Custom" ? 0 : 1;
    this.account = localStorage.getItem("account");
    if (localStorage.getItem("language")) {
      this.$i18n.locale =
        localStorage.getItem("language") === "zh_CN" ? "zh" : "en";
      this.companyType =
        localStorage.getItem("language") === "zh_CN" ? "zh" : "en";
    } else {
      localStorage.setItem("language", "zh_CN");
      this.$i18n.locale = "zh";
      this.companyType = "zh";
    }
  },
  mounted() {
    // eslint-disable-next-line no-return-assign
    window.addEventListener(
      "resize",
      () => (this.screenWidth = document.documentElement.clientWidth),
      false
    );
    // 每隔一分钟请求一次数据,单纯的使用setInterval 会使浏览器崩溃，要配合setTimeout(自带清除定时器)使用
    this.dataTimer = window.setInterval(() => {
      this.getTime();
    }, 60000);
  },
  destroyed() {
    if (this.dataTimer) {
      // 定时加载数据关闭
      clearInterval(this.dataTimer);
      this.dataTimer = "";
    }
  },
  methods: {
    changeLan(val) {
      if (val === "zh") {
        localStorage.setItem("language", "zh_CN");
      } else {
        localStorage.setItem("language", "en_US");
      }
      this.$i18n.locale = val;
      // this.cache.put('lang', val)
    },
    handleLogOut() {
      showModal(this.$t("yudata.confirmLogout")).then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("account");
        localStorage.removeItem("userInfo");

        location.reload();
      });
    },
    handleOpenUserInfoDialog() {
      this.$refs.userInfoDialogRef.showUser();
    },
    getTime() {
      return (this.date = dayjs().format("YYYY-MM-DD HH:mm"));
    },
    changeSelected(val) {
      this.isSelected = val;
      if (val === 0) {
        this.$router.push({ path: "/Custom" });
      } else if (val === 1) {
        this.$router.push({ path: "/Equipment" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mainLeft {
  width: 156px;
  height: 100%;
  background: #353e5a;
  position: relative;
  .left_content {
    width: 100%;
    margin-top: 10px;
    .left_list {
      width: 100%;
      height: 30px;
      float: left;
      line-height: 30px;
      font-size: 17px;
      margin: 20px 12px;
      cursor: pointer;
      overflow: hidden;
      .list_color {
        width: 6px;
        height: 22px;
        border-radius: 6px;
        float: left;
        margin-top: 4px;
      }
      .list_icon {
        width: 28px;
        height: 28px;
        float: left;
        margin-left: 5px;
        background-repeat: no-repeat;
      }
      .ic1 {
        background-image: url("../../assets/image/machine.png");
        background-size: 22px 22px;
        background-position: 4px 4px;
      }
      .ic2 {
        background-image: url("../../assets/image/用户@2x.png");
        background-size: 24px 24px;
        background-position: 4px 2px;
      }
      .list_text {
        float: left;
        margin-left: 10px;
        color: #d6d9e1;
      }
    }
  }
  .left_Title {
    width: 100%;
    height: 44px;
    color: #d6d9e1;
    font-size: 20px;
    line-height: 44px;
    text-align: left;
    text-indent: 12px;
    img {
      width: 120px;
      height: 32px;
      margin-top: 16px;
      margin-left: 10px;
      float: left;
    }
  }
  .left_bottom {
    .layout {
      position: absolute;
      bottom: 100px;
      width: 28px;
      height: 28px;
      left: 20px;
      background-image: url("../../assets/image/退出 (1)@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
    }
    .layout:hover {
      transform: scale(1.1);
    }
    .userName {
      width: 100%;
      text-align: left;
      text-indent: 20px;
      bottom: 70px;
      color: #d6d9e1;
      font-size: 14px;
      position: absolute;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .userName:hover {
      color: #4875ef;
    }
    .time {
      width: 100%;
      text-align: left;
      text-indent: 20px;
      bottom: 45px;
      color: #d6d9e1;
      font-size: 14px;
      position: absolute;
    }

    .selected {
      width: 70%;
      margin-left: 20px;
      bottom: 10px;
      position: absolute;

      /deep/ .el-input__inner {
        height: 26px;
        line-height: 26px;
        background-color: #353e5a;
        border: 2px solid #8f94a4;
        color: #d6d9e1;
        padding-left: 8px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      /deep/ .el-select {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      /deep/ .el-input__icon {
        width: 26px;
        line-height: 26px;
      }
      /deep/ .el-icon-arrow-up:before {
        content: "\e6e1";
        font-size: 16px;
        margin-top: 4px;
      }
      /deep/ .el-select .el-input.is-focus .el-input__inner {
        border: 2px solid #8f94a4;
      }
      /deep/ .el-select .el-input__inner:focus {
        border: 2px solid #8f94a4;
      }
      /deep/ .el-select:hover .el-input__inner {
        border: 2px solid #8f94a4;
      }
    }
  }
}
</style>
