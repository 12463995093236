import router from "../router";
import { getToken } from "./auth";
// 全局前置守卫
let hasGetInfo = false;
router.beforeEach(async (to, from, next) => {
  //   const store = managerStore();
  const token = getToken();
  if (to.path == "/login") {
    hasGetInfo = false;
  }
  // 没有登录，强制跳转回登录页
  if (!token && to.path != "/login") {
    return next({ path: "/login" });
  }
  // 防止重复登录
  // 这边要加上  loginByShare吗
  if (token && to.path == "/login") {
    // alert("不要重复登陆");
    return next({ path: from.path ? from.path : "/" });
  }
  next();
});
