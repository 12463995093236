import request from "@/js/request";
// 登录
export function Login(data) {
  return request.post("/admin", data);
}

// 发送邮箱验证码
export function sandCode(data) {
  return request.post("/reset", data);
}

// 修改密码
export function resetPwd(data) {
  return request.put("/reset", data);
}
