import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import login from "../views/login";
import NotFound from "../views/404.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  { path: "*", name: "NotFound", component: NotFound },
  {
    id: 1,
    path: "/",
    component: Layout,
    redirect: "/Custom",
    children: [
      {
        path: "Custom",
        name: "Custom",
        component: () => import("@/views/custom/index"),
        meta: { title: "Custom" },
      },
      {
        path: "Equipment",
        name: "Equipment",
        component: () => import("@/views/warning/index"),
        meta: { title: "Equipment" },
      },
      // 匹配不存在的路由 导航到404页面
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
