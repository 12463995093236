export default {
  account_reg: /^[^\u4e00-\u9fa5 ]{3,20}$/, // 账户名称 正则
  threeToTwenty: /^.{3,20}$/,
  email_reg:
    /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/, // 邮箱正则
  password_reg: /^[^ ]{6,16}$/, //6-16位 不能含有空格密码正则
  phone_reg: /^[0-9+-]+$/, //手机输入  只支持纯数字和 + -
  number: /^(\-|\+)?\d+(\.\d+)?$/, //数字
  numberTwo: /^[0-9]{2}$/,
};
