import { MessageBox, Message } from "element-ui";
import i18n from "../lang";
import dayjs from "dayjs";

// 判断变量是否未空数据
export const isEmpty = (val = null) => {
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return (
    val === "" ||
    val === null ||
    val === undefined ||
    val === "undefined" ||
    val === "null"
  );
};
// 判断搜索条件是否全为空
export const isEmptyObj = (obj) => {
  return Object.keys(obj).every((key) => isEmpty(obj[key]));
};
export function showModal(
  content = i18n.t("Tip.Tips"),
  type = "warning",
  title = ""
) {
  return MessageBox.confirm(content, title, {
    confirmButtonText: i18n.t("Login.OK"),
    cancelButtonText: i18n.t("AddMachine.Cancel"),
    type,
  });
}

// 将query对象转成url参数
export function queryParams(query = {}) {
  let q = [];
  for (const key in query) {
    if (query[key] !== undefined && query[key] !== null && query[key] !== "") {
      q.push(`${key}=${encodeURIComponent(query[key])}`);
    }
  }
  let r = q.join("&");
  r = r ? "?" + r : "";
  return r;
}

// 处理时间格式
export const handleTime = (time) => {
  if (isEmpty(time)) return "--";
  return dayjs(time * 1000).format("YYYY-MM-DD HH:mm");
};

// 二次封装接口
export function awaitWraper(promise) {
  return promise.then((res) => [null, res]).catch((err) => [err, null]);
}
let messageInstanceArr = [];
export function showMessage(
  message,
  type = "success",
  showClose = true,
  duration = 1000
) {
  // showmessgae接受的配置除了文字不同 大部分都相同 可以进一步优化
  if (messageInstanceArr.length >= 2) {
    // 如果里面存在两个以上，就把第一个关掉
    let msgToClose = messageInstanceArr.shift();
    msgToClose?.close();
    msgToClose = null;
  }
  let curMesInstance = Message({
    showClose,
    message,
    type,
    duration,
  });
  // 四秒钟后自动删除该实例
  setTimeout(() => {
    const index = messageInstanceArr.findIndex(
      (item) => item._uid == curMesInstance._uid
    );
    if (index !== -1) {
      messageInstanceArr.splice(index, 1);
    }
  }, duration);
  messageInstanceArr.push(curMesInstance);
}
// let isRepeat = true;
// export function showMessage(
//   message,
//   type = "warning",
//   showClose = true,
//   duration = 1000,
//   dangerouslyUseHTMLString = false
// ) {
//   if (!isRepeat) return;
//   Message({
//     showClose,
//     message,
//     type,
//     duration,
//     dangerouslyUseHTMLString
//   });
//   isRepeat = false;
//   setTimeout(() => {
//     isRepeat = true;
//   }, 2000);
// }

export function clearForm(obj, extra = [], defaultValObj) {
  for (let key of Object.keys(obj)) {
    if (!extra.includes(key)) {
      obj[key] = defaultValObj?.[key] ?? "";
    }
  }
}
