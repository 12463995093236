<template>
  <div>
    <el-dialog
      :title="$t('UserInfo.PersonalInformation')"
      :visible.sync="dialogVisible"
      width="515px"
      class="dialog"
      :close-on-click-modal="false"
    >
      <el-form
        ref="userInfoFormRef"
        :model="userInfoForm"
        label-width="0px"
        :rules="userInfoFormRules"
      >
        <div class="form-box">
          <el-form-item prop="account">
            <div class="label" style="text-align: left">
              {{ $t("UserInfo.Account") }}
            </div>
            <el-input v-model.trim="userInfoForm.account" disabled />
          </el-form-item>
        </div>

        <div class="form-box">
          <el-form-item prop="email">
            <div class="label" style="text-align: left">
              {{ $t("Login.Email") }}
            </div>
            <el-input
              v-model.trim="userInfoForm.email"
              :placeholder="$t('Setting.IptEmail')"
              auto-complete="off"
              clearable
            />
          </el-form-item>
        </div>
        <!-- <div class="forget_pwd" @click="forget">
          {{ $t("Login.ForgotPassword") }}
        </div> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round type="info" @click="dialogVisible = false">{{
          $t("AddMachine.Cancel")
        }}</el-button>

        <el-button round type="primary" @click="handleConfirm">{{
          $t("Login.OK")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('UserInfo.ChangePassword')"
      :visible.sync="dialogPwd"
      width="600px"
      class="dialog dialogs"
    >
      <!-- :model="userInfo" -->
      <el-form ref="workShopForm" label-width="0px">
        <div class="form-box">
          <el-form-item prop="nickname">
            <div class="label" style="text-align: left">
              {{ $t("UserInfo.CurrentPassword") }}
            </div>
            <el-input
              v-model.trim="userPwd.currentPwd"
              auto-complete="new-password"
              :placeholder="$t('Setting.oldPwd')"
              type="password"
              clearable
            />
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("UserInfo.NewPassword") }}
            </div>
            <el-input
              v-model.trim="userPwd.newPwd"
              :placeholder="$t('Login.resetThePassword')"
              auto-complete="new-password"
              type="password"
              clearable
            />
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Login.ConfirmPassword") }}
            </div>
            <el-input
              v-model.trim="userPwd.confirePwd"
              :placeholder="$t('Login.newpassword')"
              auto-complete="new-password"
              type="password"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handlePwd()"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="(dialogPwd = false), (dialogVisible = true)"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { changePwd, changeInfo } from "../../api/layout";
import { showMessage, awaitWraper } from "../../js/util";
import Constant from "../../js/constant";
import i18n from "../../lang";

export default {
  name: "userInfo",
  data() {
    return {
      userInfoForm: {
        email: "",
        account: "",
      },
      userInfoFormRules: {
        account: [
          {
            required: true,
            validator: function (rule, value, callback) {
              // 必填
              if (!value) {
                callback(new Error(i18n.t("Setting.IptAccout")));
              } else if (!Constant.account_reg.test(value)) {
                callback(new Error(i18n.t("yudata.account_reg")));
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
        email: [
          {
            required: true,
            validator: function (rule, value, callback) {
              // 必填
              if (!value) {
                callback(new Error(i18n.t("Setting.IptEmail")));
              } else if (!Constant.email_reg.test(value)) {
                callback(new Error(i18n.t("Setting.ErrEmail")));
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
      },
      userPwd: {
        newPwd: "",
        currentPwd: "",
        confirePwd: "",
      },
      repeat: true,
      repeatPower: true,
      dialogPwd: false,
      dialogVisible: false,
    };
  },
  methods: {
    forget() {
      this.userPwd.newPwd = "";
      this.userPwd.currentPwd = "";
      this.userPwd.confirePwd = "";
      this.dialogVisible = false;
      this.dialogPwd = true;
    },
    checkEmail(val) {
      const t =
        /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
      if (!t.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    async handlePwd() {
      if (!this.repeat) {
        return;
      }
      this.repeat = false;
      setTimeout(() => {
        this.repeat = true;
      }, 2000);
      if (!this.userPwd.currentPwd) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.oldPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.userPwd.newPwd) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.newPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      // if (this.userPwd.currentPwd.length < 6) {
      //   this.$message({
      //     showClose: true,
      //     message: this.$t('Tip.Digits'),
      //     type: 'warning',
      //     duration: 1000
      //   })
      //   return
      // }
      if (this.userPwd.newPwd.length < 6) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Digits"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.userPwd.newPwd !== this.userPwd.confirePwd) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Inconsistent"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const params = {};
      params.old_pwd = this.$md5(this.userPwd.currentPwd);
      params.new_pwd = this.$md5(this.userPwd.newPwd);
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      await changePwd(params, token, lang).then((res) => {
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: this.$t("Tip.Modified"),
            type: "success",
            duration: 1000,
          });
          this.$router.push("/");
        }
      });
    },
    getUserInfo() {
      // 从localstorge取出用户信息
      const localUserInfo = localStorage.getItem("userInfo");
      if (!localUserInfo) {
        return showMessage("没有用户信息", "error");
      }
      const parseData = JSON.parse(localUserInfo);
      for (const key of Object.keys(this.userInfoForm)) {
        this.userInfoForm[key] = parseData[key] ?? "";
      }
    },
    showUser() {
      this.getUserInfo();
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.userInfoFormRef.clearValidate();
      });
    },
    handleConfirm() {
      this.$refs.userInfoFormRef.validate(async (valid) => {
        if (valid) {
          const [err, res] = await awaitWraper(changeInfo(this.userInfoForm));

          if (err) return;
          location.reload();
          // const localUserInfo = JSON.parse(localStorage.getItem("userInfo"));
          // localStorage.setItem(
          //   "userInfo",
          //   JSON.stringify({
          //     ...localUserInfo,
          //     ...this.userInfoForm,
          //   })
          // );
          // this.dialogVisible = false;
          // showMessage(res.message);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 1441px) {
  .dialog /deep/.el-input__suffix {
    right: 20px !important;
  }
}
.dialogs /deep/ .el-dialog {
  margin-top: 22vh !important;
}
.dialog /deep/ .el-input__suffix {
  right: 20px;
}
.role_type {
  font-size: 14px;
  position: absolute;
  top: 24px;
}
.workshopList {
  // display: flex;
  // flex-direction: row;
  font-size: 14px;
  .workshopItem {
    float: left;
    text-align: left;
    margin-right: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.forget_pwd {
  font-size: 18px;
  text-align: left;
  color: #4875ef;
  padding: 10px 0;
  position: absolute;
  bottom: 16px;
  letter-spacing: 1px;
  cursor: pointer;
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  background: #e7e9ee;
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 30px 10px 30px;
}
.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}
.dialog /deep/ .el-select {
  width: 100%;
}
.dialog /deep/ .el-select .el-input--suffix {
  width: 100% !important;
  padding-right: 0px;
}
.dialog /deep/ .el-input {
  float: left;
  padding-right: 15px;
  // width: 80% !important;
}
.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}
.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body {
  padding: 20px 30px !important;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 30px 10px !important;
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
</style>
