<template>
  <div class="login">
    <div class="login_dialog" v-show="isLogin">
      <div class="login_title">YUDATA</div>
      <div>
        <span
          style="font-size: 14px; text-align: centerl; letter-spacing: 0.5em"
          >{{ $t("Custom.Background") }}</span
        >
      </div>
      <div class="login_form">
        <el-form :model="loginInfo" :rules="loginFormRules" ref="loginFormRef">
          <el-form-item prop="username">
            <div class="login_content">
              <div class="login_text">{{ $t("Login.Account") }}</div>
              <el-input
                class="login_input"
                type="text"
                v-model.trim="loginInfo.username"
                :placeholder="$t('Setting.IpUserName')"
                clearable
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item prop="password">
            <div class="login_content">
              <div class="login_text">{{ $t("Login.Password") }}</div>
              <el-input
                class="login_input"
                type="password"
                show-password
                v-model.trim="loginInfo.password"
                :placeholder="$t('Setting.IpPwd')"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="login_btn">
        <el-button
          type="primary"
          @click="handleLogin"
          style="width: 100%"
          :loading="loginLoading"
        >
          {{ $t("Login.SignIn") }}</el-button
        >
      </div>
      <div class="login_forget" @click="CheckForget">
        {{ $t("Login.ForgotPassword") }}
      </div>
      <!-- <div class="login_language">
        <div class="title">{{ $t("Login.Language") }}</div>
        <el-radio v-model="radio" label="1" @change="handleSetLanguage('zh')"
          >中文</el-radio
        >
        <el-radio v-model="radio" label="2" @change="handleSetLanguage('en')"
          >English</el-radio
        >
      </div> -->
    </div>
    <div class="login_reset" v-show="!isLogin">
      <div class="reset_content">
        <div class="reset_top">
          <div class="reset_title">{{ $t("Login.ResetPassword") }}</div>
          <div class="reset_space"></div>
          <el-button round type="info" plain @click="backLogin">{{
            $t("Login.Backtologinpage")
          }}</el-button>
        </div>
        <!-- <div class="login_tab">
          <div class="tab1" :style="{ borderColor: '#1883ee ' }">
            {{ $t("Login.Email") }}
          </div>
        </div> -->
        <!-- 邮箱 -->
        <div class="mailbox">
          <el-form
            ref="loginEmail"
            :model="loginEmail"
            :rules="mailboxRules"
            class="login-form"
            auto-complete="new-password"
            label-position="left"
          >
            <div class="login_item">
              <div class="login_list">
                <div class="input-title">{{ $t("Login.Email") }}</div>
                <el-form-item prop="email">
                  <el-input
                    v-model.trim="loginEmail.email"
                    :placeholder="$t('Setting.IptEmail')"
                    clearable
                    type="text"
                    tabindex="1"
                    auto-complete="new-password"
                    class="el-input-setHeight"
                  />
                </el-form-item>
              </div>
              <div class="login_list">
                <div class="input-title">
                  {{ $t("Login.VerificationCode") }}
                </div>
                <el-form-item prop="code">
                  <el-input
                    v-model.trim="loginEmail.code"
                    type="text"
                    :placeholder="$t('Login.verificationCode')"
                    clearable
                    tabindex="2"
                    auto-complete="new-password"
                    class="el-input-setHeight"
                  />
                </el-form-item>
              </div>
              <el-button
                :type="isSendEmail ? 'info' : 'primary'"
                round
                style="margin-top: 20px; min-width: 120px"
                @click="sendEmailCode"
              >
                {{ isSendEmail ? count : $t("Login.SendVerificationCode") }}
              </el-button>
            </div>
            <div class="login_item">
              <div class="login_list">
                <div class="input-title">{{ $t("Login.ResetPassword") }}</div>
                <el-form-item prop="password">
                  <el-input
                    v-model.trim="loginEmail.SetPassword"
                    :placeholder="$t('Login.resetThePassword')"
                    type="password"
                    clearable
                    tabindex="1"
                    auto-complete="new-password"
                    class="el-input-setHeight"
                  />
                </el-form-item>
              </div>
              <div class="login_list">
                <div class="input-title">{{ $t("Login.ConfirmPassword") }}</div>
                <el-form-item prop="password">
                  <el-input
                    v-model.trim="loginEmail.ConfirePassword"
                    type="password"
                    :placeholder="$t('Login.newpassword')"
                    clearable
                    tabindex="2"
                    auto-complete="new-password"
                    class="el-input-setHeight"
                  />
                </el-form-item>
              </div>
              <el-button
                round
                style="margin-top: 20px; min-width: 120px; visibility: hidden"
              >
                {{ isSendEmail ? count : $t("Login.SendVerificationCode") }}
              </el-button>
            </div>

            <div class="login_btn" @click="handleResetPwd('0')">
              <el-button type="primary" round>{{ $t("Login.OK") }}</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login, sandCode, resetPwd } from "@/api/login";
import { clearForm } from "../../js/util";
import i18n from "../../lang";
import Constant from "../../js/constant";
const TIME_COUNT = 60;

export default {
  name: "login",
  components: {},
  data() {
    return {
      isSendEmail: false,
      count: 0,
      timer: null,
      loginEmail: {
        SetPassword: "",
        ConfirePassword: "",
        password: "",
        code: "",
        email: "",
      },
      isLogin: true,
      radio: "1",
      loginInfo: {
        username: "",
        password: "",
      },
      loginLoading: false,
      loginFormRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "请输入账号",
            validator: function (rule, value, callback) {
              // 必填
              if (!value) {
                callback(new Error(i18n.t("Custom.Account")));
              } else if (!Constant.account_reg.test(value)) {
                callback(new Error(i18n.t("yudata.account_reg")));
              } else {
                callback();
              }
            },
          },
        ],
        password: [
          {
            required: true,
            validator: function (rule, value, callback) {
              // 必填
              if (!value) {
                callback(new Error(i18n.t("Setting.IpPwd")));
              } else if (!Constant.password_reg.test(value)) {
                callback(new Error(i18n.t("yudata.password_reg")));
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  computed: {
    // 邮箱重置密码验证
    // 邮箱重置密码验证

    mailboxRules() {
      return {
        code: [
          {
            required: true,
            message: this.$t("yudata.input_vertiCode"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: function (rule, value, callback) {
              // 必填
              if (!value) {
                callback(new Error(i18n.t("Setting.IpPwd")));
              } else if (!Constant.password_reg.test(value)) {
                callback(new Error(i18n.t("yudata.password_reg")));
              } else {
                callback();
              }
            },
            trigger: ["blur"],
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("Setting.IptEmail"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("Tip.IncorrectEmail"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    handleResetPwd(val) {
      this.$refs.loginEmail.validate((valid) => {
        if (valid) {
          const { SetPassword, ConfirePassword, ...otherForm } =
            this.loginEmail;
          resetPwd(otherForm).then((response) => {
            if (response.code === 200) {
              this.$message({
                showClose: true,
                message: this.$t("Tip.Modified"),
                type: "success",
                duration: 1000,
              });
              this.isLogin = true;
            }
          });
        } else {
          return false;
        }
      });
    },
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    // 获取邮箱验证码
    sendEmailCode() {
      if (this.isSendEmail) return;
      this.$refs.loginEmail.validateField("email", (error) => {
        if (!error) {
          sandCode({
            email: this.loginEmail.email,
          }).then((response) => {
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.isSendEmail = true;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.isSendEmail = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          });
        }
      });
    },

    backLogin() {
      this.isLogin = true;
    },
    CheckForget() {
      this.isLogin = false;
      this.$nextTick(() => {
        this.$refs.loginEmail.clearValidate();
      });
      clearForm(this.loginEmail);
    },
    async handleLogin() {
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          Login({
            account: this.loginInfo.username,
            password: this.$md5(this.loginInfo.password),
          })
            .then((res) => {
              if (res.code === 200) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("account", res.data.account);
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                this.$router.push("/");
              }
            })
            .finally(() => {
              this.loginLoading = false;
            });
        }
      });
    },
    handleSetLanguage(val) {
      if (val === "zh") {
        localStorage.setItem("language", "zh_CN");
        this.$i18n.locale = "zh";
      } else {
        localStorage.setItem("language", "en_US");
        this.$i18n.locale = "en";
      }
    },
  },

  mounted() {
    if (localStorage.getItem("language")) {
      this.$i18n.locale =
        localStorage.getItem("language") === "zh_CN" ? "zh" : "en";
      this.radio = localStorage.getItem("language") === "zh_CN" ? "1" : "2";
    } else {
      localStorage.setItem("language", "zh_CN");
      this.$i18n.locale = "zh";
      this.radio = "1";
    }
  },
};
</script>
<style lang="less" scoped>
@import url(./index.less);
</style>
